import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container } from '../components/UI';
import styled from '@emotion/styled';
import { EMOTION_BREAKPOINTS } from '../constants/breakpoints';
import { css } from '@emotion/react';
import theme from '../theme';
import { Link } from '../components/UI/StandardLink';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

const Section = styled.section`
  position: relative;
  align-items: center;
  a {
    cursor: pointer;
    display: block;
    margin: ${({ theme }) => theme.spacing[4]} 0;
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: 400;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.colors.text};
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    color: ${({ theme }) => theme.colors.textGrey};
  }
  display: flex;
  ${EMOTION_BREAKPOINTS.sm} {
    justify-content: space-between;
    h1 {
      font-size: ${({ theme }) => theme.fontSizes[8]};
      margin-bottom: ${({ theme }) => theme.spacing[1]};
    }
    h3 {
      max-width: 370px;
      font-size: ${({ theme }) => theme.fontSizes[4]};
      margin-bottom: 47px;
    }
  }
`;

const mastheadStyle = css`
  padding-top: 20px;
  padding-bottom: 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 40px;
    padding-bottom: 100px;
  }
`;

const AttributionsPage = ({ data }) => {
  const attributions = React.useMemo(() => {
    const attr = data.allContentfulAttribution.edges.map(edge => edge.node);
    attr.sort((a, b) => a.order - b.order);
    return attr;
  }, [data.allContentfulAttribution.edges]);
  return (
    <Layout>
      <Seo title="Attributions" />
      <Container css={mastheadStyle}>
        <Section>
          <div
            style={{
              flexBasis: theme.width(5),
              paddingRight: theme.spacing[4],
            }}
          >
            <h1>Attributions</h1>
            <h3>Illustrations and Icons</h3>
            {attributions.map((attribution) => (
              <Link
                key={attribution.url}
                to={attribution.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attribution.description}
              </Link>
            ))}
          </div>
          <div
            css={css`
              overflow: hidden;
              flex: 0 0 350px;
              z-index: 3;
              margin-right: -80%;
              ${EMOTION_BREAKPOINTS.sm} {
                margin-right: 0;
                max-width: 350px;
              }
            `}
          >
            <StaticImage
              src="../images/attributions.svg"
              alt="hero image"
              placeholder="blurred"
              width={350}
              quality={100}
            />
          </div>
        </Section>
      </Container>
    </Layout>
  );
};

export const AttributionsQuery = graphql`
  query AttributionQuery {
    allContentfulAttribution {
      edges {
        node {
          description
          order
          url
        }
      }
    }
  }
`;

export default AttributionsPage;
