import { css } from '@emotion/react';
import UILink from '../Link';
import PropTypes from 'prop-types';
import React from 'react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import theme from '../../../theme';

/**
 * Gatsby Link component with custom styles based on the theme.
 * @param {{
 *   children: React.ReactNode;
 *   to: string;
 * }} props
 * @returns
 */
export const Link = ({ children, to, ...other }) => (
  <UILink
    to={to}
    css={css`
      color: ${theme.colors.blue};
      text-decoration: none;
      font-size: 14px;

      :hover {
        text-decoration: underline;
      }
      :visited {
        color: ${theme.colors.blue};
      }

      ${EMOTION_BREAKPOINTS.sm} {
        font-size: 21px;
      }
    `}
    {...other}
  >
    {children}
  </UILink>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};
